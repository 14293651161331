<template>
  <div>
    <v-row v-if="isSales" no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="customerId"
          :items="getSearchCustomers"
          :loading="searchingCustomerFilter"
          :search-input.sync="searchCustomerWatchFilter"
          item-text="Name"
          item-value="Id"
          clearable
          hide-no-data
          :placeholder="$t('Customer')"
          @change="getDocuments()"
          return-object
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("Name") }}</th>
                <th>
                  <v-layout justify-end align-center class="mr-3">
                    {{ $t("File") }}
                  </v-layout>
                </th>
              </tr>
            </thead>
            <tbody v-if="!isLoadingDocuments && documents.length">
              <tr v-for="(document, index) in documents" :key="index">
                <td>
                  {{
                    document.Data.Name.Value[currentLanguage] ||
                    document.Data.Name.Value["Nl"] ||
                    document.Data.Name.Value["En"] ||
                    document.Data.Name.Value["Fr"]
                  }}
                </td>
                <td class="d-flex justify-end align-center">
                  <v-btn
                    :loading="isDownloading[index]"
                    :disabled="!document.Data.File.Value"
                    icon
                    color="primary"
                    @click="downloadDocument(document, index)"
                  >
                    <v-icon color="primary">mdi-file-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="documents.length == 0">
              <tr>
                <td colspan="2">
                  <v-layout
                    justify-center
                    align-center
                    fill-height
                    class="my-4"
                  >
                    {{ $t("No data available") }}
                  </v-layout>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <preloader />
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import request from "../../request";
import auth from "../../auth";
import Preloader from "./Preloader.vue";

export default {
  components: { Preloader },
  props: {
    isSales: {
      type: Boolean,
    },
  },
  data() {
    return {
      documents: [],
      isDownloading: [],
      searchCustomers: [],
      searchCustomerWatchFilter: "",
      searchingCustomerFilter: false,
      customerId: null,
      isLoadingDocuments: false,
      timerSearchCustomer: null,
    };
  },
  created() {
    if (!this.isSales) {
      this.getDocuments();
    }
  },
  computed: {
    ...mapGetters({
      websites: "allWebsites",
    }),
    getSearchCustomers() {
      if (!this.searchCustomers) {
        return [];
      }
      this.searchCustomers.forEach((element) => {
        element.Name = element.Name + " (" + element.Code + ")";
      });
      return this.searchCustomers;
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  watch: {
    searchCustomerWatchFilter: function (searchValue) {
      if (searchValue == null) {
        return;
      }
      clearTimeout(this.timerSearchCustomer);
      this.timerSearchCustomer = setTimeout(() => {
        if (searchValue != "") {
          this.searchCustomerFilter(searchValue);
        }
      }, 200);
    },
  },
  methods: {
    getDocuments() {
      this.isLoadingDocuments = true;
      const id = this.customerId ? "?customerId=" + this.customerId.Id : "";
      const url = "/api/kdesign/b2b-documents" + id;
      request.get(url, null, (res) => {
        this.isLoadingDocuments = false;
        if (res.success) {
          this.documents = res.data;
        }
      });
    },
    searchCustomerFilter(search) {
      if (this.searchingCustomerFilter) return;
      this.searchingCustomerFilter = true;
      request.get("/api/kdesign/customers?search=" + search, null, (res) => {
        this.searchCustomers = res;
        this.searchingCustomerFilter = false;
      });
    },
    downloadDocument(doc, index) {
      this.isDownloading[index] = true;
      const file = doc.Data.File.Value[this.currentLanguage]
        ? doc.Data.File.Value[this.currentLanguage][0]
        : doc.Data.File.Value["Nl"][0];

      request.download(
        "/files/" +
          doc.WebsiteId.$oid +
          "/" +
          file +
          "?token=" +
          this.getUserToken,
        { responseType: "blob" },
        file,
        () => {
          this.isDownloading[index] = false;
        }
      );
    },
  },
};
</script>
  
  <style scoped>
.height-card {
  height: 75vh;
}
</style>