<template>
    <Documents :isSales="true"/>
  </template>
  
  <script>
  import Documents from "../shared/Documents.vue";
  export default {
    components: {
      Documents,
    },
  };
  </script>
  
  <style>
  </style>